import commonHelper from '@/app/utility/common.helper.utility';
import addGstSetup from './addGstSetup';
import appStrings from '@/app/utility/string.utility';
import { required } from 'vuelidate/lib/validators';
export default {
  components: {
    addGstSetup
  },
  data() {
    return {
      setupType: {
        text: null,
        value: null
      },
      legalEntity: {
        text: null,
        value: null
      },
      perPage: commonHelper.perPageRecord,
      totalRows: null,
      vscode: null,
      unsubscribe: null,
      currentPage: null,
      showValueSetModal: false,
      showAddModal: false,
      loader: false,
      pageOptions: commonHelper.getPageOption(),
      showAddGstSetupModal: false,
      defaultValue: {
        text: null,
        value: null
      },
      editMode: false,
      gstSetUpData: [
        // {
        //   le_name: 'REVENT PRECISION ENGINEERING LIMITED',
        //   setup_type: 'abc',
        //   setup_sub_type: 'xyz',
        //   setup_value: '10'
        // }
      ],
      gstSetUpFields: [
        {
          key: 'le_name',
          label: 'Legal Entity',
          class: 'text-center width-fix'
        },
        {
          key: 'setup_type',
          label: 'Setup Type',
          class: 'text-center width-fix'
        },
        {
          key: 'setup_sub_type',
          label: 'Setup Sub Type',
          class: 'text-center width-fix'
        },
        {
          key: 'setup_value',
          label: 'Setup Value',
          class: 'text-center width-fix'
        }
      ],
      initialGstSetUpData: [],
      userId: null
    };
  },
  validations: {
    legalEntity: {
      text: {
        required
      }
    }
  },
  mounted() {
    this.userId = this.$store.state.auth.userId;
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = !this.editMode;
        }
        if (actionName === 'save') {
          this.editMode = false;
          this.updateGstSetUpDtls();
        }
      }
    });
  },
  methods: {
    getGstSetUpDtls() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          le_id: this.legalEntity.value
        };
        this.loading = true;
        this.$store
          .dispatch('gstMaster/getGstSetUpDtls', payload)
          .then(resp => {
            this.loading = false;
            if (resp.status === 200) {
              const result = resp.data.data.page;
              this.gstSetUpData = result;
              this.initialGstSetUpData = JSON.parse(JSON.stringify(result));
              this.totalRows = resp.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    updateGstSetUpDtls() {
      const itemDetails = [];
      for (let i = 0; i < this.gstSetUpData.length; i++) {
        if (
          this.gstSetUpData[i].setup_value !=
          this.initialGstSetUpData[i].setup_value
        ) {
          itemDetails.push({
            le_id: this.gstSetUpData[i].le_id,
            setup_type: this.gstSetUpData[i].setup_type,
            setup_sub_type: this.gstSetUpData[i].setup_sub_type,
            setup_value:
              this.gstSetUpData[i].setup_sub_type === 'BACKWARD'
                ? this.gstSetUpData[i].setup_value <= 0
                  ? this.gstSetUpData[i].setup_value
                  : -this.gstSetUpData[i].setup_value
                : this.gstSetUpData[i].setup_value < 0
                ? -this.gstSetUpData[i].setup_value
                : this.gstSetUpData[i].setup_value,
            user_id: this.userId
          });
        }
      }
      const payload = {
        getdata: itemDetails
      };
      this.loader = true;
      this.$store
        .dispatch('gstMaster/updateGstSetUpDtls', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            if (resp.data.status === 200) {
              this.getGstSetUpDtls();
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },

    closeValueSetModal() {
      this.showValueSetModal = false;
    },

    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      }
    },

    showHideGstMasterModal(flag) {
      this.showAddGstMasterModal = flag;
    },
    clear() {
      this.legalEntity = this.defaultValue;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.legalEntity.value) {
        this.legalEntity = this.defaultValue;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
