export default {
    name: 'addGstSetup',
    data() {
         return{
          showValueSetModal: null,
          loader: false,
          setupType:{
            text: null,
            value: null
          },
          setupSubType: null,
          setupValue: null,
          attribute1: null,
          leName:{
            text: null,
            value: null
          },
          defaultValue:{
            text: null,
            value: null
          }
        };
    },
    mounted() {},
    methods: {

      openValueSetModal(vsetCode) {
        this.vsetCode = vsetCode
        this.showValueSetModal = true;
        this.setTimeVsetCode = setTimeout(() => {
          const vSetData = {
            valueSetName: vsetCode,
            multiFlag: null
          };
          this.eventBus.$emit('valueSetCode', vSetData);
        }, 0);
      },

      closeValueSetModal() {
        // To Close Value Set Modal
        this.showValueSetModal = false;
      },
      selectedvalueSet(item) {
        // To Selected Value from value set modal
        if (this.vsetCode === 'GST_SETUP_TYPE') {
          this.setupType = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
        }else if(this.vsetCode === 'LEGAL_ENTITY_LIST'){
          this.leName = {
            text: item.value_code,
            value: item.value_set_dtl_id
          }
        }
      },
      clearVsetValues(vscode){
        if (vscode === this.leName.value){
           this.leName = this.defaultValue;
        }else if(vscode === this.setupType.value){
          this.setupType = this.defaultValue
        }
      }
},
  };